body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  /* Existing styles */
  table {
    width: 100%;
    table-layout: fixed;
  }
  th, td {
    padding: 8px;
    word-wrap: break-word;
  }
  @page {
    size: A4 landscape;    
  }
  .non-printable {
    display: none !important;
  }
  body {
    font-size: 12px;
    color: #000;
  }
  tr {
    page-break-inside: avoid;
  }
  table, th, td {
    border: 1px solid #000;
    border-collapse: collapse;
  }
  

  /* Hide specific buttons */
  .modal-footer, /* Use the class or ID specific to your buttons */
  .close-button, /* Example classes for Close and Print buttons */
  .print-button {
    display: none !important;
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-container {
  position: relative;
  display: inline-block;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 40px; /* Initial width to only show the icon */
  transition: width 0.3s;
}

.button-container:hover .button-content {
  width: 150px; /* Width to show the text */
}

.button-icon {
  display: inline-block;
  transition: transform 0.3s;
}

.button-text {
  display: inline-block;
  opacity: 0;
  display: none;
  white-space: nowrap;
  transform: translateX(-10px);
  transition: opacity 0.3s, transform 0.3s;
  margin-left: 8px;
}

.button-container:hover .button-text {
  opacity: 1;
  display: inline-block;
  transform: translateX(0);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .animate-marquee {
    animation: marquee 5s linear infinite;
    display: inline-block;
    white-space: nowrap;
  }
}